<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="zhijuyun"></Header>
    <BannerTitle title="zhijuyun"></BannerTitle>
    <div class="zjyBox">
      <div class="introduce">
        <p>{{ $t("zjy.proArticle1") }}</p>
        <p>{{ $t("zjy.proArticle2") }}</p>
      </div>
      <!-- 业务模式 -->
      <div class="pattern">
        <div class="title">{{ $t("zjy.businessMode") }}</div>
        <img
          src="@/assets/images/zhijvyun1.png"
          v-show="$i18n.locale == 'zh'"
          alt=""
        />
        <img src="@/assets/images/zhijvyun1en.jpg" v-show="$i18n.locale == 'en'" alt="" />
      </div>
      <!-- 业务场景 -->
      <div class="scene">
        <div class="title">{{ $t("zjy.sMode") }}</div>
        <img src="@/assets/images/zhijvyun2.png" alt="" />
      </div>
      <!-- 推荐引擎 -->
      <div class="engine">
        <div class="title">{{ $t("zjy.reMode") }}</div>
        <img
          src="@/assets/images/zhijvyun3.png"
          v-show="$i18n.locale == 'zh'"
          alt=""
        />
        <img v-show="$i18n.locale == 'en'" src="@/assets/images/zhijvyun3en.jpg" alt="" />
      </div>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
export default {
  name: "zhijuyun",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline
  }
};
</script>

<style lang="scss">
.zjyBox {
  width: 1300px;
  margin: auto;
  .introduce {
    width: 1000px;
    margin: auto;
    font-size: 16px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
    text-align: center;
  }
  .title {
    font-weight: 600;
    color: rgba(34, 34, 34, 1);
    line-height: 56px;
    font-size: 40px;
    text-align: center;
  }
  .pattern,
  .engine {
    margin-top: 100px;
    img {
      width: 1200px;
      margin: auto;
      display: block;
    }
  }
  .scene {
    margin-top: 71px;
    img {
      width: 1000px;
      margin: auto;
      display: block;
    }
  }
  .engine {
    margin-bottom: 113px;
  }
}
</style>
